import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import styled from 'styled-components';

import { AutoTableProps, AutoTable } from '@/ui/AutoTable';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { Skeleton } from '@/ui/Skeleton';
import { Table } from '@/ui/Table';
import { Button } from '@/ui/Button';
import { useLearningJourneyParticipantInsightsCsvLazyQueryRemote } from '@/store/v2';
import { LearnerRow, useLearnersColumnDeclaration } from '@/features/journey/admin/hooks/useLearnersColumnsDeclaration';
import { JourneyRemoveLearnerModal } from '@/features/journey/admin/molecules/JourneyRemoveLearnerModal';
import { HeaderWithAction } from '@/administration/pages/Journey/common/atoms/Page/HeaderWithAction';
import { useJourneyLearners, SortyBy } from '@/features/journey/admin/hooks/useJourneyLerners';
import { AddParticipantsModal } from '@/administration/pages/Journey/common/modals/AddParticipants';
import { useJourneyNavigate } from '@/administration/pages/Journey/utils/useJourneyNavigate';
import { useUpsertLearningJourneyCohorts } from '@/administration/pages/Journey/store/journeySession/useUpsertLearningJourneyCohorts';
import { useLearningJourneySession } from '@/administration/pages/Journey/store/journeySession/useLearningJourneySession';
import { EmptyStateAfterProcessed, EmptyStateNoData } from '@/management/components/EmptyState'; // TODO (for later PRs) Make it a generic one, so we could use it in both places
import { SearchPhrase, StatusFilter } from '@/management/pages/common/filter'; // TODO (for later PRs) Make it a generic one, so we could use it in both places
import { useManagedTeams } from '@/management/store/managedTeams/useManagedTeams';
import { useIsManagementContext } from '@/management/context/context';
import { SEARCH_PARAM_KEYS, usePageState } from '@/management/pages/common/usePageState';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { useQueryNumberArrayState } from '@/hooks/useQueryState';

type Modal = 'addParticipantsModal' | 'removeParticipantModal';

type ModalProps = {
  AddParticipants: React.ComponentProps<typeof AddParticipantsModal>;
  RemoveParticipant: React.ComponentProps<typeof JourneyRemoveLearnerModal>;
};

const PAGE_SIZE = 20;

const ContainerStyled = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LoadingState = (): React.ReactElement => (
  <Table>
    <Table.Header>
      <Table.HeaderCell expand>
        <Skeleton height={16} width={57} />
      </Table.HeaderCell>
      {[...Array(3).keys()].map((j) => (
        <Table.HeaderCell key={j}>
          <div style={{ width: 150 }}>
            <Skeleton height={16} width={57} />
          </div>
        </Table.HeaderCell>
      ))}
      <Table.HeaderCell shrink>
        <Skeleton height={16} width={57} />
      </Table.HeaderCell>
    </Table.Header>

    <Table.Body>
      {[...Array(4).keys()].map((index) => (
        <Table.Row key={index}>
          {[...Array(5).keys()].map((index) => (
            <Table.Cell key={index}>
              <Skeleton height={16} width={57} />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export const JourneyInsightsTabsParticipantsContent = (): JSX.Element => {
  const { journeyId } = useParams<{ journeyId: string }>();
  const { navigate404 } = useJourneyNavigate();
  const [selectedTeamIds] = useQueryNumberArrayState(SEARCH_PARAM_KEYS.SELECTED_TEAM_IDS, []);
  const { t } = useTranslation('common');
  const columnDeclaration = useLearnersColumnDeclaration();
  const isManagementContext = useIsManagementContext();
  const [participantToRemoveInfo, setParticipantToRemoveInfo] = useState<
    | {
        id: number;
        name: string;
      }
    | undefined
  >();

  const [activeModal, setActiveModal] = useState<Modal | null>(null);

  const [downloadCsvLink, { loading: downloadCsvLinkLoading }] = useLearningJourneyParticipantInsightsCsvLazyQueryRemote();
  const isDownloadCsvEnabled = useFeatureEnabled(FEATURE.UI_INSIGHT_PAGES_DOWNLOAD_CSV_ENABLED);
  const { upsertLearningJourneyCohorts } = useUpsertLearningJourneyCohorts();

  const {
    searchPhrase,
    setSearchPhrase,
    selectedStatus,
    setSelectedStatus,
    sortBy,
    sortOrder,
    onSortChange,
    page,
    setPage,
    onClearFilterState,
  } = usePageState<SortyBy>('name');

  const areFiltersApplied = Boolean(searchPhrase.length || selectedStatus || selectedTeamIds?.length);

  const teams = useManagedTeams({
    skip: !isManagementContext,
  });

  const { journeyLearners, loading, error, total } = useJourneyLearners({
    journeyURN: `urn:collegial:self:journey:${journeyId}`,
    sortBy,
    sortOrder,
    status: selectedStatus,
    searchPhrase: searchPhrase.length ? searchPhrase : undefined,
    limit: PAGE_SIZE,
    skip: page * PAGE_SIZE,
    teamIds: !!selectedTeamIds?.length ? selectedTeamIds : isManagementContext ? teams?.data?.map((team) => team.id) : undefined,
  });

  // TODO: Remove this once we have the API for this
  const { journeySession } = useLearningJourneySession({ id: Number(journeyId) });
  const participantsIds = journeySession?.cohort?.learners?.map((l) => l.userId) || [];

  const openAddParticipantsModal = () => {
    setActiveModal('addParticipantsModal');
  };

  const openRemoveParticipantModal = () => {
    setActiveModal('removeParticipantModal');
  };

  const handleDownloadCsvClick = async () => {
    const { data } = await downloadCsvLink({ variables: { learningJourneyId: Number(journeyId) } });
    const csvLink = data?.learningJourneyParticipantInsightsCSV;
    if (csvLink) {
      window.open(csvLink, '_blank');
    }
  };

  const handleModalClose = () => {
    setActiveModal(null);
  };

  const addParticipantsModalProps: ModalProps['AddParticipants'] = {
    open: activeModal === 'addParticipantsModal',
    onClose: handleModalClose,
    onSave: async (selectedUserIds) => {
      await upsertLearningJourneyCohorts(Number(journeyId), [...selectedUserIds, ...participantsIds]);
    },
    participantIdsToSkip: participantsIds,
  };

  const removeParticipantsModalProps: ModalProps['RemoveParticipant'] = {
    open: activeModal === 'removeParticipantModal',
    onCancel: handleModalClose,
    targetParticipantInfo: participantToRemoveInfo,
    onRemove: async (id) => {
      await upsertLearningJourneyCohorts(
        Number(journeyId),
        participantsIds.filter((participantId) => participantId !== id)
      );
    },
  };

  if (error) navigate404();

  const pageCount = Math.ceil((total ?? 0) / PAGE_SIZE);

  const handleRemoveClick = ({ id: participantId, name: participantName }: { id: number; name: string }) => {
    setParticipantToRemoveInfo({
      id: participantId,
      name: participantName,
    });
    openRemoveParticipantModal();
  };

  const tableProps: AutoTableProps<LearnerRow, SortyBy> = {
    rows: journeyLearners.map((p, _, { length }) => ({ ...p, onRemove: length !== 1 ? handleRemoveClick : undefined })),
    rowKey: (participant) => participant.id,
    columnDeclaration,
    pageCount,
    page,
    onPageChange: (event) => {
      setPage(event);
    },
    currentSortKey: sortBy,
    currentSortOrder: sortOrder,
    onSort: onSortChange,
  };

  return (
    <ContainerStyled>
      <AddParticipantsModal {...addParticipantsModalProps} />
      <JourneyRemoveLearnerModal {...removeParticipantsModalProps} />
      <>
        <HeaderWithAction
          title={t('edit-learners-page-title', { ns: 'journey' })}
          counter={total ?? 0}
          customAction={
            <ButtonGroup>
              {isDownloadCsvEnabled && (
                <Button variant="secondary" $loading={downloadCsvLinkLoading} onClick={handleDownloadCsvClick}>
                  Download (.csv)
                </Button>
              )}
              {!isManagementContext && (
                <Button variant="primary" onClick={openAddParticipantsModal}>
                  Add Learners
                </Button>
              )}
            </ButtonGroup>
          }
        />
        <ActionsStyled>
          <SearchPhrase
            value={searchPhrase}
            onChange={(phrase) => {
              setSearchPhrase(phrase);
            }}
            placeholder={t('edit-learners-table-search-placeholder', { ns: 'journey' })}
          />
          <ButtonGroup>
            <StatusFilter
              selectedStatus={selectedStatus}
              onChange={(status) => {
                setSelectedStatus && setSelectedStatus(status);
              }}
              onTrailingIconClick={() => onClearFilterState('status')}
            />
          </ButtonGroup>
        </ActionsStyled>
        {loading && <LoadingState />}
        {!loading && journeyLearners.length > 0 && <AutoTable<LearnerRow, SortyBy> {...tableProps} />}
        {!loading && journeyLearners.length === 0 && !areFiltersApplied && <EmptyStateNoData />}
        {!loading && journeyLearners.length === 0 && areFiltersApplied && (
          <EmptyStateAfterProcessed onFiltersClear={() => onClearFilterState('clear all')} />
        )}
      </>
    </ContainerStyled>
  );
};
